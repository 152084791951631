.svg-bar-highlight:hover{
  fill:rgba(225, 225, 225, 0.5);
}
.svg-button{
  fill:rgba(225, 225, 225, 0.1);
}
.svg-button:hover{
  fill:rgba(225, 225, 225, 0.5);
}

a{
  text-decoration: none;
  color:inherit
}
